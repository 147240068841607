import React from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { EngagementModelData } from '../../../data/solutions-db';

export default function EngagementModel() {
  return (
    <section className="py-5">
      <Container>
        <Row>
          <h2 className="text-center pb-4">
            {EngagementModelData[0].heading}
            {' '}
            <span className="infenox-text-highlight size-line" />
          </h2>
          {EngagementModelData[0].data.map(({
            id, heading, data, image,
          }) => (
            <Col key={id} className="col-12 col-md-6 mb-4">
              <Card className="h-100 my-auto engagement-modal-card">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title className="engagement-modal-card-heading">{heading}</Card.Title>
                      <Card.Text>{data}</Card.Text>
                    </Col>
                    {/* <Col className="col-4">
                      <img src={image} alt={heading} className="engagement-modal-card-image" />
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
